//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import {
  LABEL_PRIMARY_APPLICANT,
  LABEL_SETTLEMENT_PRIMARY_APPLICANT,
  LABEL_SETTLEMENT_SECONDARY_APPLICANT,
  LABEL_ADD_DOCUMENT,
  LABEL_NEXT,
  LABEL_BACK_BUTTON,
  LABEL_ADD_MORE_DOCUMENT_FIRST,
  LABEL_ADD_MORE_DOCUMENT_SECOND,
  LABEL_ADD_MORE_DOCUMENT_THIRD,
  LABEL_SECONDARY_APPLICANT,
  LABEL_SHARED,
  LABEL_DOCUMENT_PRIMARY,
  LABEL_DOCUMENT_SECONDARY,
  LABEL_REQUESTED_AT,
  LABEL_UPLOADED_AT,
  LABEL_DOWNLOAD_ALL_DOCUMENTS,
  LABEL_DOWNLOAD_ALL_DOCUMENTS_AS_ZIP,
  LABEL_BANK_STATEMENT_SECURE,
  LABEL_ZIP_FILE_NAME,
  LABEL_ZIP_FOLDER_NAME,
  LABEL_FILE_TYPE,
  BLOCKED_DOCUMENTS,
  LABEL_BUSINESS_DOCUMENTS,
  FINANCE_APPLICATION_COMMERCIAL_TAB_CONTACTS_KEY,
  LABEL_DOCUMENT_FOLDER_PRIMARY,
  LABEL_DOCUMENT_FOLDER_SECONDARY,
  LABEL_DOCUMENT_FOLDER_SHARED,
  SUBTAB_PRIMARY_PERSONAL_KEY,
  SETTLEMENT_DOCUMENT_LABEL,
  LABEL_DOCUMENT_SETTLEMENT,
  LABEL_PRIMARY_LOWERCASE,
  LABEL_SETTLEMENT_SECONDARY,
  LABEL_SETTLEMENT_OTHER,
  SETTLEMENT_MENU_SELLER_DETAILS_KEY,
  SETTLEMENT_MENU_LOAN_DETAILS_KEY,
  MAINTAB_LOANDETAILS_KEY,
  INFO_TITLE_FOR_SETTLEMENT,
  LABEL_SETTLEMENT_PRIMARY,
  FINANCE_APPLICATION_TAB_NOTES_KEY,
  LOAN_TYPE_PERSONAL_LOAN,
  LABEL_REQUEST_PENDING_DOCUMENTS,
  LABEL_REQUEST_PENDING_DOCUMENTS_MANUALLY,
  LABEL_RESEND_EMAIL_FOR_PENDING_DOCUMENTS,
  DEFAULT_DOCUMENT_TYPES,
  LABEL_DESCRIPTION_FOR_RESEND_EMAIL_FOR_PENDING_DOCUMENTS_SECOND,
  LABEL_DESCRIPTION_FOR_RESEND_EMAIL_FOR_PENDING_DOCUMENTS,
  LABEL_OTHER,
  LABEL_ALTERNATIVELY,
  LABEL_REQUEST_MANUALLY,
  LABEL_DESCRIPTION_FOR_RESEND_EMAIL_FOR_PENDING_DOCUMENTS_REQUEST_CUSTOMER_DASHBOARD,
  LABEL_DESCRIPTION_FOR_RESEND_EMAIL_FOR_PENDING_DOCUMENTS_REQUEST_MANUALLY,
} from '@/constants/applications'
import _ from 'lodash'
import { format } from 'date-fns'
import DocumentUpload from './DocumentUpload.vue'
import DocumentDialog from './DocumentDialog.vue'
import BankStatementUpload from './BankStatementUpload.vue'
import { changeRoute } from '~/helpers/changeRoute'
import { zipDownload } from '../utils/zipDownload'
import { getSignedURL } from '@/utils/url'
import chunk from 'lodash/chunk'
import { wait } from '~/utils'
import { Loading } from 'element-ui'
import AlertDetailsDialog from './UtilComponents/AlertDetailsDialog.vue'

export default {
  components: {
    DocumentUpload,
    DocumentDialog,
    BankStatementUpload,
    AlertDetailsDialog,
  },
  props: {
    primaryData: {
      type: Object,
      default: () => ({}),
    },
    secondaryData: {
      type: Array,
      default: () => [],
    },
    sharedData: {
      type: Object,
      default: () => ({}),
    },
    isCommercial: {
      type: Boolean,
      default: false,
    },
    isApplicationSettled: {
      type: Boolean,
      default: false,
    },
    isFromSettlementTab: {
      type: Boolean,
      default: false,
    },
    isTabDisabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      format,
      LABEL_BUSINESS_DOCUMENTS,
      LABEL_PRIMARY_APPLICANT,
      LABEL_SECONDARY_APPLICANT,
      LABEL_SETTLEMENT_PRIMARY_APPLICANT,
      LABEL_SETTLEMENT_SECONDARY_APPLICANT,
      LABEL_ADD_DOCUMENT,
      LABEL_NEXT,
      LABEL_BACK_BUTTON,
      LABEL_ADD_MORE_DOCUMENT_FIRST,
      LABEL_ADD_MORE_DOCUMENT_SECOND,
      LABEL_ADD_MORE_DOCUMENT_THIRD,
      LABEL_SHARED,
      LABEL_DOCUMENT_PRIMARY,
      LABEL_DOCUMENT_SECONDARY,
      LABEL_REQUESTED_AT,
      LABEL_UPLOADED_AT,
      LABEL_BANK_STATEMENT_SECURE,
      LABEL_DOWNLOAD_ALL_DOCUMENTS,
      LABEL_DOWNLOAD_ALL_DOCUMENTS_AS_ZIP,
      LABEL_ZIP_FOLDER_NAME,
      LABEL_ZIP_FILE_NAME,
      LABEL_FILE_TYPE,
      LABEL_DOCUMENT_FOLDER_PRIMARY,
      LABEL_DOCUMENT_FOLDER_SECONDARY,
      LABEL_DOCUMENT_FOLDER_SHARED,
      SUBTAB_PRIMARY_PERSONAL_KEY,
      SETTLEMENT_DOCUMENT_LABEL,
      LABEL_DOCUMENT_SETTLEMENT,
      LABEL_PRIMARY_LOWERCASE,
      LABEL_SETTLEMENT_SECONDARY,
      LABEL_SETTLEMENT_OTHER,
      SETTLEMENT_MENU_SELLER_DETAILS_KEY,
      SETTLEMENT_MENU_LOAN_DETAILS_KEY,
      MAINTAB_LOANDETAILS_KEY,
      INFO_TITLE_FOR_SETTLEMENT,
      LABEL_SETTLEMENT_PRIMARY,
      BLOCKED_DOCUMENTS,
      documentData: {
        ...this.primaryData,
        ...this.secondaryData,
        ...this.sharedData,
      },
      rules: {},
      dialogData: null,
      openDialog: false,
      dialogFor: null,
      applicantName: null,
      applicantId: null,
      getIsLoading: false,
      getIsDialogLoading: false,
      secondaryIndex: 0,
      documentDialogLabel: '',
      isPrimary: true,
      zipFileCounter: 0,
      documentFor: '',
      LABEL_REQUEST_PENDING_DOCUMENTS,
      LABEL_REQUEST_PENDING_DOCUMENTS_MANUALLY,
      LABEL_ALTERNATIVELY,
      LABEL_REQUEST_MANUALLY,
      alertDialog: false,
      LABEL_RESEND_EMAIL_FOR_PENDING_DOCUMENTS,
      selectedIndex: 0,
      resendApplicantId: null,
      DEFAULT_DOCUMENT_TYPES,
      LABEL_DESCRIPTION_FOR_RESEND_EMAIL_FOR_PENDING_DOCUMENTS_SECOND,
      LABEL_DESCRIPTION_FOR_RESEND_EMAIL_FOR_PENDING_DOCUMENTS,
      LABEL_DESCRIPTION_FOR_RESEND_EMAIL_FOR_PENDING_DOCUMENTS_REQUEST_CUSTOMER_DASHBOARD,
      LABEL_DESCRIPTION_FOR_RESEND_EMAIL_FOR_PENDING_DOCUMENTS_REQUEST_MANUALLY,
      loadingDialog: false,
      isRequestManually: false,
    }
  },
  computed: {
    token() {
      this.isDocumentForSettlement()
      this.checkDocumentCondition()
      this.isDocumentAvailible()
      this.isDocumentForSettlementApplicant()
      this.checkDocumentApplicantCondition()
      this.isForDocumentCommercial()
      this.isDocumentForCommercialApplicant()
      this.checkCommercialApplicant()
      return localStorage.getItem('auth._token.local')
    },
    baseURL() {
      return process.env.VUE_APP_SERVER_URL
    },
    areSharedDocumentsAvailable() {
      return (
        this.documentData?.sharedData?.selectedDocuments &&
        (this.isCommercial ||
          (!this.isCommercial && this.documentData?.secondaryData.length))
      )
    },
    primaryApplicantResendRequest() {
      var primaryApplicantDoc = []
      this.documentData?.primaryData?.selectedDocuments?.forEach((doc) => {
        if (!doc?.uploadedAt && !doc?.documentFor) {
          let documentType = doc?.name
            ? doc.name
            : DEFAULT_DOCUMENT_TYPES.find((o) => o.value === doc?.purpose)?.key

          primaryApplicantDoc.push(documentType)
        }
      })
      if (this.areSharedDocumentsAvailable) {
        this.documentData?.sharedData?.selectedDocuments?.forEach((doc) => {
          if (!doc?.uploadedAt && !doc?.documentFor) {
            let documentType = doc?.name
              ? doc.name
              : DEFAULT_DOCUMENT_TYPES.find((o) => o.value === doc?.purpose)
                  ?.key
            primaryApplicantDoc.push(documentType)
          }
        })
      }
      return primaryApplicantDoc
    },
    // eslint-disable-next-line vue/return-in-computed-property
    documentForm() {
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.getIsLoading = true
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.documentData = {
        secondaryData: JSON.parse(JSON.stringify(this.secondaryData)),
        primaryData: JSON.parse(JSON.stringify(this.primaryData)),
        sharedData: JSON.parse(JSON.stringify(this.sharedData)),
      }

      this.documentData?.primaryData?.documents?.forEach((element) => {
        if (!BLOCKED_DOCUMENTS.includes(element.purpose)) {
          let checkValueExist = false
          this.documentData.primaryData.selectedDocuments.forEach(
            (selectedEle) => {
              if (!checkValueExist && selectedEle.purpose === element.purpose) {
                checkValueExist = true
              }
            }
          )

          if (!checkValueExist)
            this.documentData.primaryData.selectedDocuments.push(element)
        }
      })

      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.documentData.primaryData.selectedDocuments =
        this.documentData?.primaryData?.selectedDocuments.filter(
          (selectedDoc) => {
            return (
              !BLOCKED_DOCUMENTS.includes(selectedDoc.purpose) &&
              !selectedDoc.deletedAt
            )
          }
        )

      if (this.documentData.secondaryData.length > 0) {
        this.documentData.secondaryData.forEach((secondUser) => {
          secondUser.documents.forEach((element) => {
            if (!BLOCKED_DOCUMENTS.includes(element.purpose)) {
              let checkSecondaryValueExist = false
              secondUser.selectedDocuments.forEach((selectedEle) => {
                if (
                  !checkSecondaryValueExist &&
                  selectedEle.purpose === element.purpose
                )
                  checkSecondaryValueExist = true
              })
              if (!checkSecondaryValueExist)
                secondUser.selectedDocuments.push(element)
            }
          })

          // eslint-disable-next-line vue/no-side-effects-in-computed-properties
          secondUser.selectedDocuments = secondUser?.selectedDocuments.filter(
            (selectedDoc) => {
              return (
                !BLOCKED_DOCUMENTS.includes(selectedDoc.purpose) &&
                !selectedDoc.deletedAt
              )
            }
          )
        })
      }

      if (this.documentData?.sharedData?.selectedDocuments) {
        this.documentData?.sharedData?.selectedDocuments.forEach((element) => {
          if (!BLOCKED_DOCUMENTS.includes(element.purpose)) {
            let checkValueExist = false
            this.documentData.sharedData.selectedDocuments.forEach(
              (selectedEle) => {
                if (
                  !checkValueExist &&
                  selectedEle.purpose === element.purpose
                ) {
                  checkValueExist = true
                }
              }
            )

            if (!checkValueExist)
              this.documentData.sharedData.selectedDocuments.push(element)
          }
        })

        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.documentData.sharedData.selectedDocuments =
          this.documentData?.sharedData?.selectedDocuments.filter(
            (selectedDoc) => {
              return (
                !BLOCKED_DOCUMENTS.includes(selectedDoc.purpose) &&
                !selectedDoc.deletedAt
              )
            }
          )
      }

      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.getIsLoading = false

      return { ...this.primaryData, ...this.secondaryData, ...this.sharedData }
    },
    primaryApplicantName() {
      return `${this.primaryData?.primaryApplicant?.firstName} ${this.primaryData?.primaryApplicant?.lastName}`
    },
  },
  mounted() {
    // this.$nuxt.$on('closeDocumentDialog', () => {
    //   this.openDialog = false
    // })
    this.$nuxt.$on('startDocumentLoader', () => {
      this.getIsDialogLoading = true
    })
    this.$nuxt.$on('submit-alert-details-dialog', (val) => {
      this.submitResendEmailForPendingDocuments(val)
    })
    this.$nuxt.$on('stopDocumentLoader', () => {
      this.getIsDialogLoading = false
    })
    this.$nuxt.$on('close-alert-details-dialog', () => {
      this.alertDialog = false
    })

    this.$nuxt.$on('closeBankStatementDialog', () => {
      this.openBankStatementDialog = false
    })
    this.$nuxt.$on('updateDocumentsList', () => {
      if (
        this.primaryData.selectedDocuments &&
        this.primaryData.selectedDocuments.length > 0
      )
        this.documentData.primaryData = {
          selectedDocuments: _.cloneDeep(this.primaryData.selectedDocuments),
        }
    })
    this.$nuxt.$on('updateOtherDocumentsList', () => {
      if (
        this.secondaryData.selectedDocuments &&
        this.secondaryData.selectedDocuments.length > 0
      )
        this.documentData.secondaryData = {
          selectedDocuments: _.cloneDeep(this.secondaryData.selectedDocuments),
        }
    })
    this.$nuxt.$on('updateSharedDocumentsList', () => {
      if (
        this.sharedData.selectedDocuments &&
        this.sharedData.selectedDocuments.length > 0
      )
        this.documentData.shareData = {
          selectedDocuments: _.cloneDeep(this.sharedData.selectedDocuments),
        }
    })
    this.$nuxt.$on('updateSettlementDocumentsList', () => {
      if (
        this.sharedData.selectedSettlementDocuments &&
        this.sharedData.selectedSettlementDocuments.length > 0
      )
        this.documentData.shareData = {
          selectedDocuments: _.cloneDeep(
            this.sharedData.selectedSettlementDocuments
          ),
        }
    })
  },
  beforeDestroy() {
    // this.$nuxt.$off('closeDocumentDialog')
    this.$nuxt.$off('closeBankStatementDialog')
    this.$nuxt.$off('updateDocumentsList')
    this.$nuxt.$off('updateOtherDocumentsList')
    this.$nuxt.$off('updateSettlementDocumentsList')
    this.$nuxt.$off('updateSharedDocumentsList')
    this.$nuxt.$off('startDocumentLoader')
    this.$nuxt.$off('stopDocumentLoader')
    this.$nuxt.$off('submit-alert-details-dialog')
    this.$nuxt.$off('close-alert-dialog')
  },
  methods: {
    filterDocumentData(data) {
      return data.filter((docs) => !BLOCKED_DOCUMENTS.includes(docs.purpose))
    },
    submitAlertDetailsDialog(val) {
      this.submitResendEmailForPendingDocuments(val)
    },
    secondaryApplicantResendRequest(val = 0) {
      var secondaryApplicantDoc = []
      if (
        this.documentData?.secondaryData?.length > 0 &&
        this.documentData?.secondaryData[val]?.selectedDocuments?.length > 0
      ) {
        this.documentData?.secondaryData[val]?.selectedDocuments?.forEach(
          (doc) => {
            if (!doc?.uploadedAt && !doc?.documentFor) {
              let documentType = doc?.name
                ? doc?.name
                : DEFAULT_DOCUMENT_TYPES.find((o) => o.value === doc?.purpose)
                    ?.key
              secondaryApplicantDoc.push(documentType)
            }
          }
        )
      }
      return secondaryApplicantDoc
    },
    ResendDocumentsSubtitle(val = 0) {
      let applicantName = this.isPrimary
        ? `${this.primaryData?.primaryApplicant?.firstName} ${this.primaryData?.primaryApplicant?.lastName}`
        : `${this.secondaryData[val]?.applicant?.firstName} ${this.secondaryData[val]?.applicant?.lastName}`
      if (this.isPrimary) {
        const subText = this.isRequestManually
          ? LABEL_DESCRIPTION_FOR_RESEND_EMAIL_FOR_PENDING_DOCUMENTS_REQUEST_MANUALLY
          : LABEL_DESCRIPTION_FOR_RESEND_EMAIL_FOR_PENDING_DOCUMENTS_REQUEST_CUSTOMER_DASHBOARD
        return `${LABEL_DESCRIPTION_FOR_RESEND_EMAIL_FOR_PENDING_DOCUMENTS} <b>${applicantName}</b> ${subText}`
      } else {
        return `${LABEL_DESCRIPTION_FOR_RESEND_EMAIL_FOR_PENDING_DOCUMENTS} <b>${applicantName}</b> ${LABEL_DESCRIPTION_FOR_RESEND_EMAIL_FOR_PENDING_DOCUMENTS_SECOND}`
      }
    },
    requestPendingDocuments(isRequestManually, val, applicantId, index) {
      this.isRequestManually = isRequestManually
      this.isPrimary = val
      this.alertDialog = true
      this.resendApplicantId = applicantId
      this.selectedIndex = index
    },
    async submitResendEmailForPendingDocuments(val) {
      this.getIsDialogLoading = true
      this.loadingDialog = true
      const { id, applicationId } = this.$route.params
      if (id && val && this.resendApplicantId) {
        await this.$store.dispatch(
          'applications/resendEmailForPendingDocument',
          {
            id,
            applicationId: applicationId,
            applicantId: val,
            isManually: this.isRequestManually,
          }
        )
        this.resendApplicantId = null
        this.alertDialog = false
      }
      this.loadingDialog = false
      this.getIsDialogLoading = false
    },
    closeDocumentDialog() {
      this.openDialog = false
    },
    checkDocumentCondition(key) {
      return this.documentData[key]?.selectedDocuments
    },
    checkDocumentApplicantCondition() {
      return this.documentData.secondaryData[0]?.selectedDocuments
    },
    isDocumentForSettlement(key) {
      return this.documentData[key]?.selectedDocuments.filter(
        this.getValidDocsBasedOnDocumentFor
      )
    },
    isDocumentForSettlementApplicant() {
      return this.documentData.secondaryData[0]?.selectedDocuments.filter(
        this.getValidDocsBasedOnDocumentFor
      )
    },
    isDocumentAvailible(key) {
      return this.documentData[key]?.selectedDocuments.filter(
        this.getValidDocsBasedOnDocumentFor
      )
    },
    isForDocumentCommercial() {
      return this.otherApplicant?.selectedDocuments
    },
    isDocumentForCommercialApplicant(document) {
      return document.selectedDocuments.filter(
        this.getValidDocsBasedOnDocumentFor
      )
    },
    checkCommercialApplicant(document) {
      return document.selectedDocuments.filter(this.getValidDocs)
    },
    getSettlementDocument(key) {
      return this.documentData[key]?.documents.filter(
        this.getValidDocsBasedOnDocumentFor
      )
    },
    getDocuments(key) {
      return this.documentData[key]?.documents?.filter(this.getValidDocs)
    },
    getSettleSharedDocument(key) {
      return this.documentData?.sharedData[key]?.filter(
        this.getValidDocsBasedOnDocumentFor
      )
    },
    getSharedDocument(key) {
      return this.documentData.sharedData[key]?.filter(this.getValidDocs)
    },
    getSecondarySettleDocument() {
      return this.documentData.secondaryData[0]?.documents.filter(
        this.getValidDocsBasedOnDocumentFor
      )
    },
    getSecondaryDocument() {
      return this.documentData.secondaryData[0]?.documents.filter(
        this.getValidDocs
      )
    },
    isSecondaryDocumentAvailable() {
      return (
        this.documentData.secondaryData &&
        this.documentData.secondaryData.length > 0
      )
    },
    getValidDocsBasedOnDocumentFor(doc) {
      return doc.documentFor && !doc.deletedAt
    },
    getValidDocs(doc) {
      return !doc.documentFor && !doc.deletedAt
    },
    download(file) {
      let files = []
      if (
        file.primaryData?.documents.filter((x) => !x.deletedAt).length > 0 ||
        this.isDocumentForSettlement('primaryData').length > 0
      ) {
        this.isFromSettlementTab
          ? files.push(...this.getSettlementDocument('primaryData'))
          : files.push(...this.getDocuments('primaryData'))
      }
      if (file.secondaryData.length > 0) {
        this.isFromSettlementTab
          ? files.push(
              ...file.secondaryData
                .map((docs) =>
                  docs.documents.filter(this.getValidDocsBasedOnDocumentFor)
                )
                .flat()
            )
          : files.push(
              ...file.secondaryData
                .map((docs) => docs.documents.filter(this.getValidDocs))
                .flat()
            )
      }
      if (
        file.sharedData?.sharedDocuments.filter((x) => !x.deletedAt).length >
          0 ||
        this.isDocumentForSettlement('sharedData').length > 0
      ) {
        this.isFromSettlementTab
          ? files.push(...this.getSettleSharedDocument('sharedDocuments'))
          : files.push(...this.getSharedDocument('sharedDocuments'))
      }
      this.batchDownload(files)
    },
    async batchDownload(files = []) {
      if (!files.length) {
        return
      }
      let loadingInstance = Loading.service({ fullscreen: true })
      if (files.length <= 2) {
        await this.downloadDocuments(files)
      } else {
        const chunkedArray = chunk(files, 2)
        await Promise.all(
          chunkedArray.map((chunkedFiles, i) =>
            this.delayedDownload(chunkedFiles, 2 * i)
          )
        )
      }
      loadingInstance.close()
    },
    async delayedDownload(files, delay) {
      await wait(delay)
      await this.downloadDocuments(files)
    },
    async downloadDocuments(file) {
      let data = file.map((doc) => {
        return {
          name: doc.name ?? doc?.purpose,
          url: doc.url,
          filename: doc.filename,
          objectKey: doc.objectKey,
          purpose: doc.purpose,
        }
      })
      let filesForDownload = []
      filesForDownload = data.map((file) => {
        return {
          path: getSignedURL(file?.url, this.$auth),
          name: file?.filename ? file?.filename : file?.name,
          purpose: file.purpose,
        }
      })
      const promiseData = await Promise.all(
        filesForDownload.map((filesForDownload) => {
          if (this.blockedDocument(filesForDownload.purpose)) {
            return this.$axios({
              method: 'get',
              url: filesForDownload.path,
              responseType: LABEL_FILE_TYPE,
            })
          }
        })
      )
      promiseData.map((data) => {
        if (data !== undefined) {
          filesForDownload.map((file) => {
            if (file.path === data.config.url) {
              this.downloadFile(data, file)
            }
          })
        }
      })
    },
    downloadFile(response, file) {
      let headers = response.headers
      let blob = new Blob([response.data], {
        type: headers['content-type'],
      })
      let link = document.createElement('a')
      link.href = window.URL.createObjectURL(blob)
      link.download = file.name
      link.click()
      link.remove()
    },
    downloadAllZipDocuments(file) {
      this.zipFileCounter = 0
      let allFiles = []
      if (
        file.primaryData?.documents.length > 0 ||
        this.isDocumentForSettlement('primaryData').length > 0
      ) {
        let data = {
          doc: this.isFromSettlementTab
            ? this.getSettlementDocument('primaryData')
            : this.getDocuments('primaryData'),
          label: this.isCommercial
            ? this.primaryApplicantName
            : this.LABEL_DOCUMENT_FOLDER_PRIMARY,
        }
        allFiles.push(data)
      }
      if (
        file.sharedData?.sharedDocuments?.length > 0 ||
        this.isDocumentForSettlement('sharedData').length > 0
      ) {
        let data = {
          doc: this.isFromSettlementTab
            ? this.getSettleSharedDocument('sharedDocuments')
            : this.getSharedDocument('sharedDocuments'),
          label: this.isFromSettlementTab
            ? this.INFO_TITLE_FOR_SETTLEMENT
            : !this.isCommercial
            ? this.LABEL_DOCUMENT_FOLDER_SHARED
            : this.LABEL_BUSINESS_DOCUMENTS,
        }
        allFiles.push(data)
      }
      let data
      if (this.isCommercial && file.secondaryData.length > 0) {
        let secondaryFiles = []
        file.secondaryData.map((documents) => {
          documents.documents.map((file) => {
            if (file.documentFor && this.isFromSettlementTab) {
              secondaryFiles.push(file)
            } else if (!file.documentFor) {
              secondaryFiles.push(file)
            }
          })

          data = {
            doc: secondaryFiles,
            label:
              documents.applicant?.firstName +
              ' ' +
              documents.applicant?.lastName,
          }
          secondaryFiles = []
          allFiles.push(data)
        })
      }
      if (file.secondaryData[0]?.documents?.length > 0 && !this.isCommercial) {
        data = {
          doc: this.isFromSettlementTab
            ? this.getSecondarySettleDocument()
            : this.getSecondaryDocument(),
          label: this.LABEL_DOCUMENT_FOLDER_SECONDARY,
        }
        allFiles.push(data)
      }
      this.downloadZip(allFiles)
    },
    CreteDirectorFolder(file) {
      let allFiles = []
      let data = {
        doc: file.documents,
        label:
          file.secondaryData?.applicant?.firstName +
          ' ' +
          file.secondaryData?.applicant?.lastName,
      }
      allFiles.push(data)
      this.downloadZip(allFiles)
    },
    downloadZip(file) {
      let data = file.map((document) => {
        return document.doc.map((f) => {
          return {
            name: f.name ?? f.purpose,
            url: getSignedURL(f.url, this.$auth),
            filename: f.filename ?? f.name,
            objectKey: f.objectKey,
            purpose: f.purpose,
            label: document.label,
          }
        })
      })
      let filesForDownload = []
      filesForDownload = data.map((file) => {
        return file.map((file) => {
          return {
            path: file.url,
            name: file.filename,
            purpose:
              file.purpose === LABEL_OTHER
                ? file.purpose
                : file.name
                ? file.name
                : file.purpose,
            label: file.label,
          }
        })
      })
      let folderName = {
        firstName: this.primaryData.primaryApplicant.firstName,
        lastName: this.primaryData.primaryApplicant.lastName,
        shortId: this.sharedData.shortId,
      }
      zipDownload(filesForDownload, this.zipFileCounter, folderName)
    },
    blockedDocument(documentName) {
      return !BLOCKED_DOCUMENTS.includes(documentName)
    },
    addDocument({ type, index = 0, docType }) {
      this.openDialog = true
      this.setApplicantID(type)
      this.secondaryIndex = index
      this.dialogFor = type
      if (type === this.LABEL_DOCUMENT_PRIMARY) {
        this.documentDialogLabel = ''
        this.documentFor = docType
        this.dialogData = this.filterDocumentData(
          this.primaryData.selectedDocuments
        )
      } else if (
        type === this.LABEL_SHARED ||
        type === this.LABEL_BUSINESS_DOCUMENTS ||
        type === this.LABEL_DOCUMENT_SETTLEMENT
      ) {
        this.documentFor = docType
        this.documentDialogLabel = ''
        this.dialogData = this.filterDocumentData(
          this.sharedData.selectedDocuments
        )
      } else {
        if (
          this.documentData.secondaryData &&
          this.documentData.secondaryData.length
        ) {
          this.documentFor = docType
          this.documentDialogLabel =
            this.documentData?.secondaryData[index]?.applicant?.firstName ?? ''
          this.dialogData = this.filterDocumentData(
            this.secondaryData[index].selectedDocuments
          )
        } else {
          this.dialogData = []
        }
      }
    },
    setApplicantID(type) {
      if (
        [
          LABEL_DOCUMENT_PRIMARY,
          LABEL_SHARED,
          LABEL_BUSINESS_DOCUMENTS,
          LABEL_DOCUMENT_SETTLEMENT,
        ].includes(type)
      ) {
        this.applicantId = this.documentData?.primaryData?.primaryApplicant._id
      } else {
        this.applicantId =
          this.documentData?.secondaryData[this.secondaryIndex]?.applicant
            ?._id || ''
      }
    },
    async previousSectionRedirect() {
      const { id, applicationId } = this.$route.params
      await changeRoute({
        route: this.$route,
        router: this.$router,
        path: `/finance/applications/${id}/${applicationId}`,
        query: {
          maintab: MAINTAB_LOANDETAILS_KEY,
        },
      })
      this.$nuxt.$emit('updateComponent', MAINTAB_LOANDETAILS_KEY)
    },
    backToPrevious() {
      if (this.isFromSettlementTab) {
        this.previousSectionRedirect()
      } else {
        if (!this.isCommercial) {
          return this.redirectToPage('assetsLiabilities')
        }
        return this.redirectToPage(
          FINANCE_APPLICATION_COMMERCIAL_TAB_CONTACTS_KEY
        )
      }
    },
    sectionRedirectForSettlement() {
      if (this.isTabDisabled) {
        this.redirectToPage(FINANCE_APPLICATION_TAB_NOTES_KEY)
      } else {
        const redirectTab =
          this.sharedData.loanType === LOAN_TYPE_PERSONAL_LOAN
            ? SETTLEMENT_MENU_LOAN_DETAILS_KEY
            : SETTLEMENT_MENU_SELLER_DETAILS_KEY
        this.$emit('sectionRedirect', redirectTab)
      }
    },
    next() {
      if (this.isFromSettlementTab) {
        this.sectionRedirectForSettlement()
      } else {
        this.redirectToPage('compliance')
      }
    },
    async redirectToPage(pagename) {
      if (this.isCommercial) {
        return this.$nuxt.$emit('changeTab', {
          tab: pagename,
          subTab: `primary_${SUBTAB_PRIMARY_PERSONAL_KEY}`,
        })
      }
      const { id, applicationId } = this.$route.params
      await changeRoute({
        route: this.$route,
        router: this.$router,
        path: `/finance/applications/${id}/${applicationId}`,
        query: {
          maintab: pagename,
        },
      })
      this.$nuxt.$emit('updateComponent', pagename)
    },
    getMappedNameWithPurpose(documentName) {
      const result = DEFAULT_DOCUMENT_TYPES.filter(
        (type) => type.value === documentName
      )
      return result && result[0] ? result[0].key : documentName
    },
  },
}
